import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import useComponentFactory from '../hooks/useComponentFactory';
import PageDataContext from '../state/PageDataContext';
import Seo from '../components/Seo/Seo';
import ThinkingArticleIntro from '../components/ThinkingArticleIntro/ThinkingArticleIntro';
import PageTransition from 'gatsby-plugin-page-transitions';

const InsightPageTemplate = ({ data, location, ...rest }) => {
  const pageData = data.contentfulThinking;
  const components = useComponentFactory(pageData.components);
  return (
    <PageTransition>
      <PageDataContext.Provider value={{ ...pageData, ...rest, location }}>
        <Seo
          contentTitle={pageData.title}
          pathname={location.pathname}
          seo={pageData.seo}
        />
        <Layout>
          <ThinkingArticleIntro {...pageData} />
          {components}
        </Layout>
      </PageDataContext.Provider>
    </PageTransition>
  );
};

export const pageQuery = graphql`
  query InsightPageById($id: String!) {
    contentfulThinking(id: { eq: $id }) {
      title
      body {
        raw
      }
      date(formatString: "DD/MM/YY")
      image {
        gatsbyImageData
        file {
          url
        }
        title
      }
      seo {
        canonicalUrl
        description
        seoTitle
        image {
          file {
            url
          }
          title
          width
          height
        }
        hidePageFromSearchEngines
        excludeFromSitemap
      }
      author {
        authorJobTitle
        authorName
      }
      subheading {
        subheading
      }
      components {
        __typename

        ... on ContentfulAward {
          ...ContentfulAwardFields
        }
        ... on ContentfulClient {
          ...ContentfulClientFields
        }
        ... on ContentfulComponentFullWidthImage {
          ...ContentfulComponentFullWidthImageFields
        }
        ... on ContentfulComponentGetInTouch {
          ...ContentfulComponentGetInTouchFields
        }

        ... on ContentfulComponentImageCarousel {
          ...ContentfulComponentImageCarouselFields
        }
        ... on ContentfulComponentPageHeader {
          ...ContentfulComponentPageHeaderFields
        }
        ... on ContentfulComponentQuote {
          ...ContentfulComponentQuoteFields
        }
        ... on ContentfulComponentShareStory {
          ...ContentfulComponentShareStoryFields
        }
        ... on ContentfulComponentProjectSpotlight {
          ...ContentfulComponentProjectSpotlightFields
        }
        ... on ContentfulComponentStats {
          ...ContentfulComponentStatsFields
        }
        ... on ContentfulComponentText {
          ...ContentfulComponentTextFields
        }
        ... on ContentfulComponentVideo {
          ...ContentfulComponentVideoFields
        }
        ... on ContentfulComponentHeading {
          ...ContentfulComponentHeadingFields
        }
        ... on ContentfulComponentTwoColumnImageAndDescription {
          ...ContentfulComponentTwoColumnImageAndDescriptionFields
        }
        ... on ContentfulComponentRelatedContent {
          ...ContentfulComponentRelatedContentBlogsFields
        }
        ... on ContentfulComponentAnimatedHeading {
          ...ContentfulComponentAnimatedHeadingFields
        }
        ... on ContentfulComponentThreeColumnContent {
          ...ContentfulComponentThreeColumnContentFields
        }
        ... on ContentfulComponentContentSpotlight {
          ...ContentfulComponentContentSpotlight
        }
        ... on ContentfulComponentEmbed {
          ...ContentfulComponentEmbedFields
        }
      }
    }
  }
`;

export default InsightPageTemplate;
