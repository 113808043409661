import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import useComponentFactory from '../hooks/useComponentFactory';
import PageDataContext from '../state/PageDataContext';
import Seo from '../components/Seo/Seo';
import PageTransition from 'gatsby-plugin-page-transitions';

const myHTML = `
<div class="PageHeading-module_page-heading-wrapper__WaZK4" style="padding-bottom: 0;">
   <div class="PageHeading-module_page-heading-custom-bgr__ArT04">
      <div class="PageHeading-module_page-heading__e-l5B">
         <div class="PageHeading-module_page-heading__wrapper__a8pO3">
            <h1 class="PageHeading-module_page-heading__title__6WGys PageHeading-module_page-heading__title--fixed-size__LHqFk">Digital Experience Index</h1>
         </div>
      </div>
   </div>
</div>`;

const DEXPageTemplate = ({ data, location, ...rest }) => {
  const pageData = data.contentfulContact;
  const components = useComponentFactory(pageData.components);

  return (
    <PageTransition>
      <PageDataContext.Provider value={{ ...pageData, ...rest, location }}>
        <Seo
          contentTitle={pageData.title}
          pathname={location.pathname}
          seo={pageData.seo}
        />

        <Layout>
          <div dangerouslySetInnerHTML={{ __html: myHTML }} />
          {components}
        </Layout>
      </PageDataContext.Provider>
    </PageTransition>
  );
};

export const pageQuery = graphql`
  query DEXPageById($id: String!) {
    contentfulContact(id: { eq: $id }) {
      title
      seo {
        canonicalUrl
        description
        seoTitle
        image {
          file {
            url
          }
          title
          width
          height
        }
        hidePageFromSearchEngines
        excludeFromSitemap
      }
      heroImage {
        gatsbyImageData
        file {
          url
        }
        title
      }
      locations {
        name
        googleMapsUrl {
          googleMapsUrl
        }
        contactNumber
        emailAddress
        address {
          raw
        }
      }
      components {
        __typename

        ... on ContentfulAward {
          ...ContentfulAwardFields
        }
        ... on ContentfulComponentFullWidthImage {
          ...ContentfulComponentFullWidthImageFields
        }
        ... on ContentfulComponentGetInTouch {
          ...ContentfulComponentGetInTouchFields
        }
        ... on ContentfulComponentImageCarousel {
          ...ContentfulComponentImageCarouselFields
        }
        ... on ContentfulComponentPageHeader {
          ...ContentfulComponentPageHeaderFields
        }
        ... on ContentfulComponentQuote {
          ...ContentfulComponentQuoteFields
        }
        ... on ContentfulComponentShareStory {
          ...ContentfulComponentShareStoryFields
        }
        ... on ContentfulComponentProjectSpotlight {
          ...ContentfulComponentProjectSpotlightFields
        }
        ... on ContentfulComponentStats {
          ...ContentfulComponentStatsFields
        }
        ... on ContentfulComponentText {
          ...ContentfulComponentTextFields
        }
        ... on ContentfulComponentVideo {
          ...ContentfulComponentVideoFields
        }
        ... on ContentfulComponentHeading {
          ...ContentfulComponentHeadingFields
        }
        ... on ContentfulComponentTwoColumnImageAndDescription {
          ...ContentfulComponentTwoColumnImageAndDescriptionFields
        }
        ... on ContentfulComponentAwards {
          ...ContentfulComponentAwardsFields
        }
        ... on ContentfulComponentLottieAnimation {
          ...ContentfulComponentLottieAnimationFields
        }
        ... on ContentfulComponentCallOut {
          ...ContentfulComponentCallOutFields
        }
        ... on ContentfulComponentClientGrid {
          ...ContentfulComponentClientGrid
        }
        ... on ContentfulComponentEmbed {
          ...ContentfulComponentEmbedFields
        }
        ... on ContentfulComponentCta {
          ...ContentfulComponentCtaFields
        }
        ... on ContentfulComponentContentSpotlight {
          ...ContentfulComponentContentSpotlight
        }
        ... on ContentfulComponentRelatedContent {
          ...ContentfulComponentRelatedContentPagesFields
        }
        ... on ContentfulComponentClientMissionsCarousel {
          ...ContentfulComponentClientMissionsCarouselFields
        }
        ... on ContentfulComponentAnimatedHeading {
          ...ContentfulComponentAnimatedHeadingFields
        }
        ... on ContentfulComponentThreeColumnContent {
          ...ContentfulComponentThreeColumnContentFields
        }
      }
    }
  }
`;

export default DEXPageTemplate;
